import './App.css';
import { Outlet, ScrollRestoration } from 'react-router-dom'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className='inner'>
      <Header />
      <Outlet />
      <Footer />
      <ScrollRestoration />
    </div>
  );
}

export default App;
