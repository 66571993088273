import React from 'react'
import styles from './Contact.module.scss'

export default function Contact() {
   return (
      <div className={styles.sectionContact}>
         <div className={styles.containerContact}>
            <p>Pour toute interrogation, demande ou réservation, je vous invite à prendre contact avec moi.</p>
            <div className={styles.links}>
               <i className="fa-regular fa-envelope fa-lg"></i>
               <a href="mailto:rayan.elhour@outlook.fr" target="_blank" rel="noreferrer">rayan.elhour@outlook.fr</a>
            </div>
            <div className={styles.links}>
               <i className="fa-solid fa-phone "></i>
               <span>07 81 71 67 40</span>
            </div>
         </div>
      </div>
   )
}
