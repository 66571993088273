import React from 'react'
import styles from './Footer.module.scss'
import { NavLink } from 'react-router-dom'


export default function Footer() {
  return (
    <footer>
      <div className={styles.footer}>
        <p>Rayan Holistique 2023</p>
        <span>|</span>
        <p><NavLink to="/mentions-legales">Mentions Légales</NavLink></p>
      </div>
    </footer>
  )
}
