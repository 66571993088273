import React from 'react'
import angel from '../../assets/images/gold_angel.jpg';
import rayan from '../../assets/images/Rayan.jpg';
import styles from './Home.module.scss'
import { useSpring, animated } from '@react-spring/web'

export default function Home() {
  const springs = useSpring({
    from: { opacity: 0  },
    to: { opacity: 1  },
    config: { duration: 1000 }
  })

  return (
    <>
      <div className={styles.container}>
        <animated.div
          style={{ 

            borderRadius: 8,
            ...springs
          }}
        >
          <img src={angel} alt="Ange" />
        </animated.div>
        <blockquote>« Vers la&nbsp;connaissance de&nbsp;soi. »</blockquote>
      </div>
      <div className={styles.txtAccueil}>
        <p>Bienvenue sur mon site de thérapie holistique ! Je suis à la fois professionnel de santé et magnétiseur – énergéticien. Je crois en l’approche globale de la santé permettant la guérison du corps, de l’esprit et de l’âme. Je propose mes services dans le but de vous accompagner dès lors que vous en sentez le besoin.</p>
        <img className={styles.imgRayan} src={rayan} alt="Ange" />
        <p>Mon approche holistique combine différentes passes magnétiques pour aider à restaurer l’équilibre et la santé dans votre vie. Je propose des séances  en cabinet à l'ESPACE TERAPYA au 40 bis rue du faubourg poissonière dans le 10ème arrondissement de Paris. Mon but étant de vous aider à atteindre votre bien-être optimal.</p>
        <p>Je suis là pour vous aider à mieux comprendre vos problématiques, conscientisées ou non, et à découvrir les moyens les plus efficaces pour aller vers une connaissance de soi. Je vous invite à explorer mon site et à découvrir comment la thérapie holistique peut vous aider à atteindre une santé durable et équilibrée.</p>
        <p>Pour élaborer un plan de traitement personnalisé, je prends en compte vos antécédents médicaux, votre mode de vie et vos objectifs de santé.</p>
      </div>
    </>
  )
}
