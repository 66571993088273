import React from 'react'
import App from './App';
import { createBrowserRouter } from 'react-router-dom';
import Home from './pages/HomePage/Home'
import Ethique from './pages/EthiquePage/Ethique'
import SoinsEnergetiques from './pages/SoinsEnergetiquesPage/SoinsEnergetiques'
import Tarifs from './pages/Tarifs/Tarifs'
import Contact from './pages/ContactPage/Contact'
import MentionsLegales from './pages/MentionsLegalesPage/MentionsLegales'



export const router = createBrowserRouter([
   {
      path:'/',
      element: <App/>,
      children:[
         {
            path:'/',
            element: <Home/>
         },
         {
            path:'/ethique',
            element: <Ethique/>
         },
         {
            path:'/prestations',
            element: <SoinsEnergetiques/>
         },
         {
            path:'/tarifs',
            element: <Tarifs/>
         },
         {
            path:'/contact',
            element: <Contact/>
         },
         {
            path:'/mentions-legales',
            element: <MentionsLegales/>
         },

      ]
   }
])
