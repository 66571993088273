import React from 'react'
import styles from './Ethique.module.scss'

export default function Ethique() {
  return (
    <div id="topEthique">
      <div className='inner'>
        <div className={styles.txtAccueil_Ethique}>
          <h1>L’ÉTHIQUE DU MAGNÉTISEUR</h1>
          <p><span>1.</span> Il ne formule pas de diagnostic médical. Il ne fait jamais suspendre un traitement médical en cours sans l’accord du médecin traitant et ne s’oppose pas à une intervention chirurgicale.</p>

          <p><span>2.</span> Il ne suggère jamais à son patient d’interrompre le suivi médical, les examens ou les investigations nécessaires à déterminer ou préciser la /les cause(s) de sa maladie.</p>

          <p><span>3.</span> S’il le juge nécessaire dans l’intérêt – et pour assurer la sécurité de son patient – il l’adressera à un médecin mieux à même de traiter son cas avec toute la compétence nécessaire.</p>

          <p><span>4.</span> Il ne divulgue les résultats de son intervention qu’avec l’assentiment de son patient.</p>

          <p><span>5.</span> Il est tenu de respecter le secret professionnel et d’observer la plus grande discrétion en toutes circonstances.</p>

          <p><span>6.</span> Il se fait un devoir d’apaiser, de soulager ou d’atténuer, jusqu’à l’extrême limite de ses moyens, la souffrance de ceux qui ont recours à lui.</p>

          <p><span>7.</span> Il prêtera son assistance et sa compétence – bénévolement si nécessaire – aux indigents, aux porteurs de handicaps et aux malades en phase terminale, à la demande ou sur les conseils du médecin traitant.</p>

          <p><span>8.</span> Il ne recevra pas en consultation et ne traitera pas les mineurs ou les déficients mentaux hors de la présence de leurs parents ou de leur tuteur légal.</p>

          <p><span>9.</span> Dans ses relations avec son patient, il restera sobre, honnête et de bonne foi. Il évitera dans son discours les allégations mensongères, les paroles maladroites ou nocives. Il ne bercera pas le consultant d’illusions trompeuses. Il s’interdira de faire appel à des superstitions. Il ne se livrera pas à la prévarication.</p>

          <p><span>10.</span> Dans l’exercice de sa profession, le guérisseur-magnétiseur ne surestimera ni ses compétences, ni ses possibilités. Cette attitude garantira à son patient l’innocuité de son action, celle-ci demeurant strictement complémentaire de l’acte médical.</p>

          <p><span>11.</span> Il devra préserver, discipliner et coordonner ses dons naturels, ses qualités intellectuelles et morales.</p>

          <p><span>12.</span> Il devra se mettre en conformité avec les obligations administratives du/des Etat(s) dans lequel/ lesquels il exerce son activité et s’astreindra à respecter les normes ou les statuts en vigueur.</p>

          <p><span>13.</span> Il se refuse à assimiler la profession de guérisseur-magnétiseur à une quelconque science occulte, à confondre son art avec celui des arts divinatoires ou de la parapsychologie.</p>

          <p><span>14.</span> Il s’engage sur l’honneur et sous serment à respecter les Statuts, le Code de Déontologie et les Règlements du GNOMA et du SNAMAP.</p>

          <p><span>15.</span> Primum, non nocere (d’abord ne pas nuire) telle doit être la devise du guérisseur-magnétiseur.</p>

          <cite>
          Source :<a className={styles.linkSource} rel="noreferrer" href='https://gnoma-snamap.fr/charte-du-guerisseur-magnetiseur/' target="_blank"> https://gnoma-snamap.fr/charte-du-guerisseur-magnetiseur/</a></cite>

        </div>
      </div>
    </div>
  )
}
