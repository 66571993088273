import React from 'react'
import styles from './Tarifs.module.scss'

export default function Tarifs() {
   return (
      <div className={styles.txtAccueil_tarifs}>
         <h1>TARIFS DES SOINS</h1>
         <h2>Invitation paisible</h2>
         <ul>
            <li>Soin énergétique apaisant</li>
            <li>60 min - 60 euros</li>
         </ul>
         <h2>Régénération</h2>
         <ul>
            <li>Accompagnement à la cicatrisation post-opératoire</li>
            <li>50 min - 50 euros</li>
         </ul>
         <h2>Eau du feu</h2>
         <ul>
            <li>Soulagement des brûlures de premier et deuxième degrés</li>
            <li>50 min - 50 euros</li>
         </ul>
         <h2>Activation</h2>
         <ul>
            <li>Soulagement d'affections physiques</li>
            <li>60 min - 60 euros</li>
         </ul>
      </div>
   )
}
