import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import styles from './Header.module.scss'
import logo from '../../assets/images/Logo_RH_black.png';
import { useState } from "react"
import HeaderMobileMenu from './HeaderComponents/HeaderMobileMenu'

export default function Header() {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <nav className='d-flex justify-content-center align-items-center zIndex100'>
      <div className={styles.logo}>
        <Link to="/"><img src={logo} alt="logo Rayan Holistique" /></Link>
      </div>
      <div className={styles.menuDesktop}>
        <NavLink to="/" >Accueil</NavLink>
        <NavLink to="/ethique">L’éthique du magnétiseur</NavLink>
        <NavLink to="/prestations">Prestations</NavLink>
        <NavLink to="/tarifs">Tarifs</NavLink>
        <NavLink to="/contact">Contact</NavLink>
        <a href="https://www.instagram.com/rayan_holistique/" target="blank"><i className="fa-brands fa-instagram fa-lg"></i></a>
      </div>

      <i onClick={() => setShowMenu(true)} className={`${styles.burgerMenu} fa-solid fa-bars fa-xl`}></i>
      {showMenu &&
        <>
          <div onClick={() => setShowMenu(false)} className='calc'></div>
          <HeaderMobileMenu setShowMenu={setShowMenu} />
        </>
      }

    </nav>
  )
}
