import React from 'react'
import styles from './HeaderMobileMenu.module.scss'
import { Link } from 'react-router-dom'


export default function HeaderMobileMenu({ setShowMenu }) {

  function handleClick() {
    setShowMenu(false)
  }

  return (
    <ul className={`${styles.menuContainer}`} >
      <li onClick={handleClick}><Link to="/">Accueil</Link></li>
      <li onClick={handleClick}><Link to="/ethique">L’éthique du magnétiseur</Link></li>
      <li onClick={handleClick}><Link to="/prestations" >Prestations</Link></li>
      <li onClick={handleClick}><Link to="/tarifs" >Tarifs</Link></li>
      <li onClick={handleClick}><Link to="/contact" >Contact</Link></li>
      <li onClick={handleClick}><a href="https://www.instagram.com/rayan_holistique/" target="blank"><i className="fa-brands fa-instagram fa-lg"></i></a></li>
    </ul>
  )
}
