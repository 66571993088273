import React from 'react'
import styles from './MentionsLegales.module.scss'

export default function MentionsLegales() {
  return (
    <div className={styles.container_ML}>
      <div>
        <h1>Mentions Légales - CGV et RGPD</h1>

        <h2>1. PRÉSENTATION DU SITE.</h2>

        <p>En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site rayanholistique.fr l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :</p>
        <p><strong>Propriétaire :</strong> Rayan El hour (EI) est joignable par mail rayan.elhour@outlook.fr ou par téléphone au 07 81 71 67 40. La domiciliation administrative se situe au 25 rue de Paris, 93230 Romainville. Numéro de siret : 97853242200026</p>
        <p><strong>Création du site :</strong> Vincent Fouque</p>
        <p><strong>Hébergeur :</strong> Netlify, dont le siège social est situé 2325 3rd Street, Suite 296, San Francisco, California 94107.</p>
      </div>
      <div>
        <h2>2. LIMITATIONS CONTRACTUELLES SUR LES DONNÉES TECHNIQUES.</h2>

        <p>Le site utilise la technologie JavaScript. Le site Internet ne pourra être tenu responsable de dommages matériels liés à l’utilisation de celui-ci. De plus, l’utilisateur s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour</p>
      </div>
      <div>
        <h2>3. PROPRIÉTÉ INTELLECTUELLE ET CONTREFAÇONS.</h2>

        <p>Rayan El hour est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes et logo. Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de : Rayan El hour. Toute exploitation non autorisée du site ou de l’un des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions de l'article L.335-2 du Code de la propriété intellectuelle.</p>
      </div>
      <div>
        <h2>4. LIMITATIONS DE RESPONSABILITÉ.</h2>

        <p>Rayan El hour ne pourra être tenu responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site rayanholistique.fr, et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées au point 2, soit de l’apparition d’un bug ou d’une incompatibilité. </p>
      </div>
      <div>
        <h2>5. CONDITIONS G&Eacute;N&Eacute;RALES DE VENTE</h2>

        <h3>Objet & Champ d'application</h3>

        <p>Les présentes conditions générales de vente (CGV) définissent, les modalités contractuelles régissant les prestations de services réalisées par <span class="fw-bold">Rayan Holistique</span> -
          micro-entreprise au n°SIRET 978532422 00026 - spécialisée dans le domaine du magnétisme, des soins énergétiques et de la sonothérapie.</p>
        <p>Les CGV s'appliquent à toutes les transactions conclues entre Rayan Holistique et ses clients dans le cadre de son activité.</p>
        <p>Rayan Holistique vous accueillera au sein de <span class="fw-bold">l'espace TERAPYA</span>, qui est un espace de santé naturelle, situé au <span class="td-underline">40 bis rue du faubourg poissonière</span>, Paris, France, 75010.</p>
        <p>Toute commande de prestation de services implique l'adhésion sans réserve, du client, aux présentes conditions générales de vente, sauf accord écrit préalable contraire entre les parties. En passant commande, le client reconnaît avoir pris connaissance des dites conditions et les accepte pleinement.</p>

        <h3>Commandes & Prestations</h3>

        <p>Les commandes sont passées par <span class="fw-bold">e-mail</span>, par <span class="fw-bold">instagram</span> ou par <span class="fw-bold">téléphone</span> auprès de Rayan Holistique. Les prestations de services sont décrites dans les informations fournies sur le site internet <a class="style_link_base" href="http://www.rayanholistique.fr" rel="noopener, noreferrer" target="_blank">rayanholistique.fr</a> et sur le compte Instagram @rayan_holistique. Le client reconnaît avoir pris connaissance de la nature et des caractéristiques des prestations avant de passer commande. Rayan Holistique s'engage à fournir les prestations de services avec <strong>tout le professionnalisme requis</strong>, en mettant en oeuvre les compétences appropriées dans le respect des normes et des pratiques du domaine. Le prestataire intervient en fonction de ses disponibilités. À l’issue de la réalisation de la prestation, cette dernière est considérée comme étant livrée dès lors qu’elle a été réalisée intégralement conformément à ce qui aura été convenu entre les parties.</p>

        <h3>Tarifs & Modalités de Paiement</h3>

        <p>Les tarifs sont librement fixés par le prestataire et sont susceptibles de varier en fonction de l’ampleur et de la nature de la prestation à réaliser. Ils sont disponibles sur le site internet à l'onglet « Tarifs ». En tout état de cause, le montant total est indiqué au client préalablement à la validation de sa commande et à son règlement. Le paiement s'effectue comptant, <strong>en fin de séance</strong> par <strong>espèces</strong> ou par <strong>carte bancaire</strong>.</p>

        <h3>Responsabilité</h3>

        <p>Le prestataire est tenu par une <strong>obligation de moyens</strong> quant à la prestation qui est réalisée. Par conséquent, il s’engage à réaliser la prestation conformément à ce qui aura été convenu entre les parties.</p>
        <p>Le client reconnaît que les prestations de services dans le domaine du magnétisme, des soins énergétiques et de la sonothérapie sont des <strong>méthodes complémentaires</strong> et <strong>ne se substituent pas à des traitements médicaux</strong>. Le client doit informer Rayan Holistique de toutes <strong>contre-indications</strong> existantes à la pratique du magnétisme. Elles sont notifiées sur le site du prestataire. Il s'agira notamment, <strong>des femmes enceintes lors du premier trimestre, des personnes porteuses d'un pacemaker, des enfants de moins de trois psychotiques et des troubles schizophréniques ainsi que des personnes épileptiques.</strong></p>
        <h3>Protection des données personnelles</h3>

        <p>Rayan Holistique s'engage à <strong>respecter la confidentialité</strong> des informations personnelles fournies par le client dans le cadre des prestations de services. Le client accepte que ses données personnelles soient utilisées dans le cadre de la prestation de services et conformément à la politique de confidentialité de Rayan Holistique.</p>
        <p>Pour exercer votre droit de rectification, de suppression, d'effacement, de portabilité, de limitation et d'opposition, vous pouvez contacter le prestataire :</p>
        <p>Par e-mail à l’adresse suivante : <a href="mailto:rayan.elhour@outlook.fr">rayan.elhour@outlook.fr</a></p>

        <h3>Force majeur</h3>

        <p>La responsabilité du Prestataire ne pourra pas être mise en oeuvre si la non-exécution ou le retard dans l’exécution de l’une de ses obligations décrites dans les présentes conditions générales de vente découle d’un cas de force majeure au sens de l’article 1218 du Code civil.</p>

        <h3>Litiges & Médiation de la consommation</h3>

        <p>En cas de litige entre le client et l’entreprise, ceux-ci s’efforceront de le résoudre à l’amiable (le client adressera une réclamation écrite auprès du professionnel).</p>
        <p>À défaut d’accord amiable ou en l’absence de réponse du professionnel dans un délai raisonnable d’un mois, le client consommateur au sens de l’article L.133-4 du code de la consommation a la possibilité de saisir gratuitement, si un désaccord subsiste, le médiateur compétent inscrit sur la liste des médiateurs établie par la Commission d’évaluation et de contrôle de la médiation de la consommation en application de l’article L.615-1 du code de la consommation, à savoir :</p>
        <p>La Société Médiation Professionnelle <br />
          <a href="www.mediateur-consommation-smp.fr" rel="noopener, noreferrer" target="_blank">www.mediateur-consommation-smp.fr</a><br />
          <strong>24 rue Albert de Mun - 33000 Bordeaux</strong>
        </p>

        <h3>Nota bene</h3>
        <p>Le prestataire se réserve le droit de mettre à jour, ses conditions générales de vente, en fonction de l'évolution de ses services et prestations. Le client sera informé de tout changement.</p>
      </div>
      <div>
        <h2>Politique de Confidentialité</h2>
        <p>En tant que thérapeute holistique, je suis déterminé à respecter la confidentialité et la sécurité de vos données personnelles. Cette politique de confidentialité vise à vous informer de la manière dont je collecte, utilise et protège vos informations conformément au <strong>Règlement général sur la protection des données (RGPD)</strong>.</p>
        <p>Conformément à la <strong>loi n°2018-493 du 20 juin 2018 relative à la protection des données personnelles</strong> et au RGPD, je vous informe que les données collectées via la fiche de soin et le compte-rendu, font l'objet d'un traitement dont le responsable est Rayan Holistique (EI). Les données sont strictement collectées et utilisées dans le cadre de mon activité.</p>
        <span className={styles.title_data}>Ces données peuvent inclure :</span>
        <ul>
          <li>Votre identité (nom, prénom, date de naissance)</li>
          <li>Votre adresse postale</li>
          <li>Votre adresse e-mail</li>
          <li>Votre numéro de portable</li>
          <li>Vos données de santé et antécédents médicaux (Votre consentement est primordial)</li>
        </ul>
        <span className={styles.title_data}>J'utilise ces données personnelles de la manière suivante :</span>
        <ul>
          <li>Pour planifier et coordonner mon accompagnement auprès de vous</li>
          <li>Pour adapter mes services à vos besoins individuels</li>
          <li>Pour vous contacter lors de la prise de rendez-vous</li>
          <li>Pour maintenir des dossiers précis de nos interactions</li>
        </ul>
        <p>Je vous informe, notamment, que je suis l'unique destinataire de vos données personnelles et elles ne sont en aucun cas partagées, cédées ou échangées.</p>
        <p>Sous réserve des obligations légales ou réglementaires de conservation pouvant peser sur Rayan Holistique (EI), vos données personnelles sont conservées pendant la durée nécessaire à mon activité. <strong>Elles seront supprimées 5 ans après notre dernier échange.</strong></p>
        <p>Vous bénéficiez d'un droit d'accès, de rectification, de suppression, d'effacement, de portabilité, de limitation et d'opposition au traitement de données vous concernant.</p>
        <p>Si vous souhaitez exercer l'un de ces droits, vous pouvez me contacter par mail à <a href="mailto:rayan.elhour@outlook.fr">rayan.elhour@outlook.fr</a>.</p>
        <p>Conformément à la réglementation applicable, vous êtes en droit d'introduire une réclamation auprès de la <strong>Commission Nationale de l'Informatique et des Libertés (CNIL)</strong>.</p>
        <p>Je me réserve le droit de mettre à jour cette politique de confidentialité en fonction de l'évolution des lois et réglementations. Vous serez informé de tout changement.</p>
        <p>Par ailleurs, les fichiers électroniques sont protégés par un mot de passe et les fichiers papier sont archivés au siège social de Rayan Holistique.</p>
        <p>La protection de votre vie privée est capitale, ainsi, sachez que je respecterai toujours la confiance que vous m'accordez.</p>

      </div>
    </div>
  )
}
