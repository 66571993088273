import React from 'react'
import styles from './SoinsEnergetiques.module.scss'
import chakrasParasitage from '../../assets/images/chakras_parasitage.jpeg'
import chakrasHarmonisation from '../../assets/images/chakras_harmonisation2.jpeg'
import symbolsChakras from '../../assets/images/symbols_chakras.jpg'
import problemsChakras from '../../assets/images/problems_chakras.jpg'
import organsChakras from '../../assets/images/chakras_organs.jpg'

export default function SoinsEnergetiques() {
  return (

    <div className={styles.txtAccueil_soinsEnergetiques}>
      <h1>PRESTATIONS</h1>
      <p>Avant tout soin énergétique, nous communiquerons ensemble pour que je puisse réaliser une anamnèse. Cela consiste en un interrogatoire dont le but est de recueillir les motifs de votre venue lors de la séance, vos antécédents médicaux ainsi que la nature de vos symptômes.</p>

      <p>Ma pratique se divise en deux temps : le temps du bilan énergétique et le temps du accompagnement. Pour la phase de bilan, je vous demanderais de m'envoyer une photographie plein-pied par e-mail. La photographie agit comme le reflet de votre état énergétique. Cela me permet de connaître le taux d'équilibre de vos chakras et l'état de votre aura, mais aussi de comprendre vos problématiques actuelles.</p>

      <p>Le accompagnement se réalise en présentiel sur Paris, je vous ferais un compte-rendu écrit à la suite de la séance. </p>

      <p>Tous les soins énergétiques que je propose, commencent par des passes magnétiques dont le but est d'harmoniser vos chakras, votre aura, vos organes et vos articulations. Les soins énergétiques sont recommandés autant pour les personnes en bonne santé, désirant faire l'expérience du magnétisme que pour les personnes présentant des pathologies.</p>

      <p><strong>Je vous rappelle que les soins énergétiques constituent un accompagnement et ne se substituent pas à vos traitements médicaux.</strong></p>

      <p className={styles.prestations}>Voici les prestations que je propose :</p>
      <p className={styles.soinEnergetique}>Soin énergétique apaisant : "Invitation paisible"</p>

      <p className={styles.listPrestations}>Harmonisation des 7 chakras majeurs</p>
      <div className={styles.chakras}>
        <img src={chakrasParasitage} alt="Déséquilibre des 7 chakras" />
        <img src={chakrasHarmonisation} alt="Harmonisation des 7 chakras" />
      </div>
      <p>Les chakras majeurs correspondent aux centres énergétiques de votre organisme. Ils captent, transforment et redistribuent les énergies cosmo-telluriques provenant de l'environnement. La transmission de l'énergie vitale aux différents organes, se réalise par l'intermédiaire de canaux énergétiques, appelés Nadis.</p>
      <p>Les chakras sont des zones à mouvement rotatif et tourbillonant, qui se déploient à la face antérieure et à la face postérieure du corps. Seuls les chakras racine et couronne ont une direction unique, vers la terre pour l'un et vers le ciel pour l'autre. Lors des soins énergétiques, je réalise un check-up des 7 chakras majeurs, dans le but de saisir leur dysfonctionnement et de les harmoniser.</p>

      <p className={styles.listPrestations}>Harmonisation de l’enveloppe énergétique</p>
      <div className={styles.chakras}>
        <img src={symbolsChakras} alt="Les 7 chakras" />
        <img src={problemsChakras} alt="Les 7 chakras" />
      </div>
      <p>Les 7 chakras majeurs sont reliés aux différents corps subtils que nous possédons. L'ensemble de ces corps subtils forment l'enveloppe énergétique ou aura. Ainsi, l'enveloppe énergétique est composée des corps éthérique, astral, mental, causal, bouddhique et atmique :</p>
      <ul>
        <li>- Le corps éthérique est en lien avec le chakra sacré (plan vital)</li>
        <li>- Le corps astral est en lien avec le chakra du plexus solaire (plan émotionnel)</li>
        <li>- Le corps mental est en lien avec le chakra du coeur (plan de la réflexion)</li>
        <li>- Le corps causal est en lien avec le chakra de la gorge (plan de la mémoire lointaine)</li>
        <li>- Le corps bouddhique est en lien avec le chakra du troisième oeil (plan de la connaissance)</li>
        <li>- Le corps atmique est en lien avec le chakra couronne (plan de la conscience cosmique)</li>
      </ul>
      <p>Les expériences, le mode de vie et les relations toxiques peuvent induire des problèmes énergétiques dans l'aura. Les trous créent une fuite d'énergie et permettent l'accés aux énergies indésirables. Les déchirures sont des signes de traumatismes physiques ou psychiques. L'énergie stagnante est causée lorsque l'individu retient trop longtemps des pensées ou des émotions sans les exprimer. Si cette énergie se prolonge dans le temps,  elle cristallise pour former une boue énergétique.</p>
      <p>Les soins énergétiques que je prodigue ont pour but de fortifier l'aura et de libérer toute émotion enfouie.</p>

      <p className={styles.listPrestations}>Harmonisation des organes et des articulations</p>
      <div className={styles.chakras}>
        <img src={organsChakras} alt="Les 7 organes" />
      </div>
      <p>Chaque organe possède une énergie vitale qui lui ait propre. Sur le plan anatomo-physiologique, chaque chakra majeur est lien avec une glande endocrine spécifique.</p>
      <p>La correspondance entre les chakras et les organes est capitale car quand il existe une problématique sur l'un des chakras, il n'est pas rare de voir la repercussion que cela a sur le plan organique.</p>
      <p>Voici la correspondance entre les chakras et les glandes endocrines :</p>
      <ul>
        <li>- Le chakra racine est lié aux glandes surrénales</li>
        <li>- Le chakra sacré est lié aux gonades (testicules ou ovaires)</li>
        <li>- Le chakra du plexus solaire est lié au pancréas et de façon globale au système digestif</li>
        <li>- Le chakra du coeur est lié au thymus</li>
        <li>- Le chakra de la gorge est lié à la thyroïde</li>
        <li>- Le chakra du troisième oeil est lié à l'hypophyse</li>
        <li>- Le chakra couronne est lié à la glande pinéale</li>
      </ul>
      <p>Les articulations sont des zones de connexion entre les membres, sur le plan énergétique, le flux peut se retrouver bloquer au niveau des zones articulaires, ce qui freine la circulation de l'énergie au niveau des membres.<br /> Lors des soins énergétiques, je libère les zones articulaires et permet une libre circulation de l'énergie au niveau des membres supérieurs et inférieurs.</p>
      <p className={styles.listPrestations}>Je tiens à préciser que le soin énergétique apaisant que je réalise auprès de vous comprend l'harmonisation des chakras, de l'enveloppe énergétique, des organes et des articulations. C'est un soin complet qui permet de mieux vous connaître, d'aller vers l'avant, et d'être aligné à votre conscience supérieure.</p>

      <p className={styles.soinEnergetique}>Régénération</p>
      <p className={styles.listPrestations}>Accompagnement à la cicatrisation post-opératoire</p>
      <p>Cette prestation permet d'accélerer le processus de cicatrisation à la suite d'une opération chirurgicale. Ce soin est à réaliser une semaine maximum après l'opération. Lors de ce soin, je réalise une suture du corps éthérique au niveau de la zone opérée et j'harmonise l'énergie au niveau de cette zone puis j'accélère la cicatrisation.</p>

      <p className={styles.soinEnergetique}>Eau du feu</p>
      <p className={styles.listPrestations}>Soulagement des brûlures de premier et deuxième degrés</p>
      <p>Cette prestation est un accompagnement que je réalise en urgence auprès de vous si vous avez des brûlures de premier et deuxième degrés. Le but de ce soin est d'éviter la propagation du feu dans les cellules et les tissus, de soulager la douleur, d'accélérer la cicatrisation de la plaie et de travailler sur l'origine émotionnelle de la brûlure selon le type et la localisation sur le corps physique. <strong>Ce soin doit faire suite à des soins médicaux adaptés en première intention.</strong></p>

      <p className={styles.listPrestations}>Soulagement d’affections physiques</p>
      <p>Cette prestation comprend un soin de magnétisme pour soulager vos maux physiques. Il s'agit d'un accompagnement qui est complémentaire à tout soin médical. N'hésitez pas à me faire part de vos problématiques de santé. L'accompagnement peut être prolongé dans le cadre de pathologies chroniques.</p>

      <p className={styles.soinEnergetique}>Sonothérapie</p>
      <p className={styles.listPrestations}>Recommandation : Je vous recommande d'effectuer le soin invitation paisible en amont de tout autre soin pour harmoniser vos énergies et ainsi pouvoir travailler sur des bases saines. Si vous voulez choisir un autre type de soin sans avoir à réaliser le soin invitation paisible, veuillez m'en faire part.</p>

      <div className={styles.chakras}>
      </div>

      <p className={styles.prestations}>Cependant, il existe des contre-indications à la pratique du magnétisme dans les cas suivants :</p>

      <p className={styles.contreIndications}>Les femmes enceintes lors du premier trimestre</p>
      <p className={styles.contreIndications}>Les personnes porteuses d’un pacemaker</p>
      <p className={styles.contreIndications}>Les enfants de moins de trois mois</p>
      <p className={styles.contreIndications}>Les personnes présentant une bipolarité non stabilisée, des troubles psychotiques et des troubles schizophréniques</p>
      <p className={styles.contreIndications}>Les personnes épileptiques</p>
    </div>
  )
}
